<template>
  <div class="favq">
    <heand :tagnum="tagnum"></heand>

    <div class="enterprise clearfix">
      <div class="left">
        <ziyuannavleft />
      </div>
      <div class="right">
        <ul class="proje_ul">
          <li class="qy_names">
            <strong>企业名称：</strong>
            <span>
              <el-input v-model="company_name" placeholder="请输入"></el-input>
            </span>
          </li>
          <li>
            <strong>成立时间：</strong>
            <span>
              <el-date-picker v-model="build_date" type="date" placeholder="选择时间"></el-date-picker>
            </span>
          </li>
          <li class="li_widan">
            <strong>联系人：</strong>
            <span>
              <el-input v-model="admin" placeholder="请输入"></el-input>
            </span>
          </li>
          <li>
            <strong>联系电话：</strong>
            <span>
              <el-input v-model="mobile" type="number" placeholder="请输入"></el-input>
            </span>
          </li>
          <li>
            <strong>联系邮箱：</strong>
            <span>
              <el-input v-model="email" placeholder="请输入"></el-input>
            </span>
          </li>
          <li>
            <strong>项目名称：</strong>
            <span>
              <el-input v-model="name" placeholder="请输入"></el-input>
            </span>
          </li>
          <li class="li_widan">
            <strong>生产产品：</strong>
            <span>
              <el-input v-model="product" placeholder="请输入"></el-input>
            </span>
          </li>
          <li>
            <strong>所在行业：</strong>
            <span>
              <el-select
                placeholder="请选择"
                v-model="industry"
                @change="labelchange($event,industryarr,1)"
              >
                <el-option
                  v-for="(item,index) in industryarr"
                  :key="index"
                  :label="item.name"
                  :value="item.industry_id"
                ></el-option>
              </el-select>
            </span>
          </li>
          <li>
            <strong>投资金额：</strong>
            <span>
              <el-input v-model="invest" type="number" placeholder="请输入"></el-input>亿元
            </span>
          </li>
          <li>
            <strong>面积：</strong>
            <span>
              <el-input v-model="area" type="number" placeholder="请输入"></el-input>亩
            </span>
          </li>
          <li class="li_widan">
            <strong>预计年产值：</strong>
            <span>
              <el-input v-model="value" type="number"  placeholder="请输入"></el-input>万元
            </span>
          </li>
          <li>
            <strong>预计税收：</strong>
            <span>
              <el-input v-model="tax" type="number" placeholder="请输入"></el-input>万元
            </span>
          </li>
          <li>
            <strong>入库时间：</strong>
            <span>
              <el-date-picker v-model="store" type="date" placeholder="选择时间"></el-date-picker>
            </span>
          </li>
          <li>
            <strong>项目对接人：</strong>
            <span>
              <el-input v-model="link" placeholder="请输入"></el-input>
            </span>
          </li>
          <li class="li_widan">
            <strong>对接人联系方式：</strong>
            <span>
              <el-input v-model="link_mobile" type="number" placeholder="请输入"></el-input>
            </span>
          </li>
        </ul>

        <ul class="xm_dengji">
          <li>
            项目等级：
            <el-select v-model="level" placeholder="请选择" @change="labelchange($event,levelarr,2)">
              <el-option
                v-for="(item,index) in levelarr"
                :key="index"
                :label="item.name"
                :value="item.level_id"
              ></el-option>
            </el-select>
          </li>
          <li>
            最新进展：
            <el-select v-model="stage" placeholder="请选择" @change="labelchange($event,stagearr,3)">
              <el-option
                v-for="item in stagearr"
                :key="item.stage_id"
                :label="item.name"
                :value="item.stage_id"
              ></el-option>
            </el-select>
          </li>
        </ul>

        <div class="project_main">
          <dl class="project_tit">
            <dt>项目介绍</dt>
          </dl>
          <div class="project_area">
            <el-input type="textarea" :rows="2" placeholder="请输入项目主体介绍" v-model="main_introduce"></el-input>
          </div>
          <div class="project_area">
            <el-input type="textarea" :rows="2" placeholder="请输入投资项目介绍" v-model="invest_introduce"></el-input>
          </div>
          <div class="project_area">
            <el-input type="textarea" :rows="2" placeholder="请输入项目建设内容" v-model="build_introduce"></el-input>
          </div>
          <div class="project_area">
            <el-input type="textarea" :rows="2" placeholder="请输入项目经济效益" v-model="encomy"></el-input>
          </div>
          <div class="project_area">
            <el-input type="textarea" :rows="2" placeholder="请输入项目政策需求" v-model="policy"></el-input>
          </div>

          <dl class="project_tit">
            <dt>项目进展及存在的问题</dt>
          </dl>
          <div class="project_area">
            <el-input type="textarea" :rows="2" placeholder="请输入项目进展及存在的问题" v-model="question"></el-input>
          </div>
          <div class="pro_btns">
            <a class="chengse_btn" @click="savedata()">保存</a>
          </div>
          <!-- <dl class="project_tit">
            <dt>项目文件</dt>
            <dd v-if="upload">
              <el-button @click="alerterr" size="small" type="primary">点击上传</el-button>
            </dd>
            <dd v-if="!upload">
              <el-upload
                :headers="{token: token}"
                :before-upload="beforeAvatarUpload"
                :on-success="successfile"
                :on-error="errorfile"
                auto-upload
                multiple
                class="upload-demo"
                action="http://tyzhaoshanggraph.industrychain.online/api/manage/upItemFile"
                :data="{item_id:33}"
                :file-list="fileList"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </dd>
          </dl>
          <div class="project_tables">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td style="width:10%">序号</td>
                <td style="width:25%;">文件名</td>
                <td style="width:25%">文件大小</td>
                <td style="width:10%;">发布人</td>
                <td>操作</td>
              </tr>
              <tr style="width:100%" v-for="(item,index) in trtd" :key="index">
                <td style="width:10%">{{index}}</td>
                <td style="width:25%;">{{item.name}}</td>
                <td style="width:25%">{{item.size}}</td>
                <td style="width:10%;">{{item.user}}</td>
                <td style="cursor: pointer;" @click="apidelete(item.id,index)">删除</td>
              </tr>
            </table>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listAddItem, addItem, apidelete, detailsItem } from "@/api/index";
export default {
  data() {
    return {
      token: "",
      tagnum: 7,
      fileList: [],
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      currentPage4: 4,
      visible: false,
      build_date: "", //   		# 公司成立时间
      company_name: "", //   		# 项目企业
      admin: "", //  			# 联系人
      mobile: "", //   			# 联系电话
      email: "", //			# 联系邮箱
      name: "", //			# 项目名称
      store: "", // 			# 入库时间
      product: "", //  			# 生产产品
      industry: "", //  			# 行业
      indu_id: "", // 			# 所在行业id
      invest: "", // 			# 投资额(亿元)
      area: "", //   			# 占地面积（亩）
      value: "", //   			# 预计产值(亿元)
      tax: "", //  			# 预计税收(亿元)
      link: "", //   			# 对接人
      link_mobile: "", //  		# 对接人的联系方式
      level: "", //   			# 项目的级别
      level_id: "", //  			# 项目级别ID
      stage: "", //  			# 项目的进度
      stage_id: "", //  			# 项目进度id
      main_introduce: "", //  		# 项目主体介绍
      invest_introduce: "", //		# 投资项目介绍
      build_introduce: "", // 		# 项目建设内容
      encomy: "", // 			# 项目的经济效益
      policy: "", // 			# 项目政策需求
      question: "", //   			# 项目存在的问题
      industryarr: [], // 能源
      stagearr: [], // 进度
      levelarr: [],
      upload: true,
      itemid: "",
      trtd: [],
      id: ""
    };
  },
  created() {
    this.token = localStorage.getItem("login");
    this.id = this.$route.query.id;
    this.lookoredit = this.$route.query.lookoredit;
    // this.getdetailsItem();
    this.getlistAddItem();
  },
  // created() {
  //   this.getlistAddItem();
  // },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    async getlistAddItem() {
      const res = await listAddItem();
      this.industryarr = res.industry;
      this.stagearr = res.stage;
      this.levelarr = res.level;
    },
    labelchange(e, objx, num) {
      let obj = {};
      switch (num) {
        case 1:
          obj = objx.find(item => {
            return item.industry_id === e; //筛选出匹配数据
          });

          this.industry = obj.name;
          this.indu_id = e;
          break;
        case 2:
          obj = objx.find(item => {
            return item.level_id === e; //筛选出匹配数据
          });

          this.level = obj.name;

          this.level_id = e;
          break;
        case 3:
          obj = objx.find(item => {
            return item.stage_id === e; //筛选出匹配数据
          });

          this.stage = obj.name;

          this.stage_id = e;
          break;
      }
    },
    successfile(file) {
      if (file.code == 0) {
        this.trtd.push(file.data);
        this.$message({
          message: file.msg,
          type: "success"
        });
      } else {
        this.$message.error(file.msg);
      }
    },
    errorfile(res) {
      this.$message.error(res.msg);
    },
    beforeAvatarUpload(file) {
      var fileName = file.name
        .substring(file.name.lastIndexOf(".") + 1)
        .toLowerCase();
      if (
        fileName != "xlsx" &&
        fileName != "xls" &&
        fileName != "pdf" &&
        fileName != "ppt" &&
        fileName != "pptx" &&
        fileName != "doc" &&
        fileName != "ocx" &&
        fileName != "zip" &&
        fileName != "txt" &&
        fileName != "rar"
      ) {
        this.$message.error("文件格式不对");
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < 16;
      if (!isLt2M) {
        this.$message.error("文件大小不能超过 16MB!");
      }
      return isLt2M;
    },
    async apidelete(id, index) {
      const res = await apidelete({
        id: id
      });
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.trtd.splice(index, 1);
      }
    },
    async savedata() {
      var data = {
        build_date: this.build_date, //   		# 公司成立时间
        company_name: this.company_name, //   		# 项目企业
        admin: this.admin, //  			# 联系人
        mobile: this.mobile, //   			# 联系电话
        email: this.email, //			# 联系邮箱
        name: this.name, //			# 项目名称
        store: this.store, // 			# 入库时间
        product: this.product, //  			# 生产产品
        industry: this.industry, //  			# 行业
        indu_id: this.indu_id, // 			# 所在行业id
        invest: this.invest, // 			# 投资额(亿元)
        area: this.area, //   			# 占地面积（亩）
        value: this.value, //   			# 预计产值(亿元)
        tax: this.tax, //  			# 预计税收(亿元)
        link: this.link, //   			# 对接人
        link_mobile: this.link_mobile, //  		# 对接人的联系方式
        level: this.level, //   			# 项目的级别
        level_id: this.level_id, //  			# 项目级别ID
        stage: this.stage, //  			# 项目的进度
        stage_id: this.stage_id, //  			# 项目进度id
        main_introduce: this.main_introduce, //  		# 项目主体介绍
        invest_introduce: this.invest_introduce, //		# 投资项目介绍
        build_introduce: this.build_introduce, // 		# 项目建设内容
        encomy: this.encomy, // 			# 项目的经济效益
        policy: this.policy, // 			# 项目政策需求
        question: this.question //   			# 项目存在的问题
      };
      const res = await addItem(data);
      if (res.code == 0) {
        this.$message({
          message: "保存成功",
          type: "success"
        });
        this.itemid = res.item_id;
        this.uploadname = res.name;
        this.upload = false;
        this.$router.push({name:'projectgl'})
      } else {
        this.$message({
          message: res.msg,
          type: "error"
        });
      }
    },
    //保存文件后才允许上传
    alerterr() {
      this.$message.error("请先保存文件");
    }
  }
};
</script>

<style lang="less" scoped>
.favq {
  width: 100%;
  background: rgb(243, 243, 243);

  li {
    list-style: none;
  }

  .c-top {
    width: 1920px;
    height: 72px;
    background: #fff;

    li {
      display: block;
      width: 100px;
      height: 38px;
      background: #eee;
      margin-top: 19px;
      border-radius: 6px;
      margin-right: 19px;
      font-size: 17px;
      font-weight: bold;
      color: #666666;
      text-align: center;
      line-height: 38px;
      float: left;
      &:nth-child(1) {
        margin-left: 52px;
      }
      &.active {
        background: rgb(254, 147, 34);
        color: #ffffff;
      }
    }
  }
}
.enterprise {
  padding: 0 30px;
}
.left {
  float: left;
  background: #fff;
  min-width: 170px;

  .dxhj {
    padding-top: 13px;
    width: 100%;
    height: 38px;

    img {
      float: left;
      width: 38px;
      height: 38px;
      margin-left: 13px;
    }

    span {
      float: left;
      line-height: 31px;
      height: 18px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #222222;
    }
  }

  /deep/ .el-collapse-item__header {
    height: 40px;
    padding-left: 40px;
    font-size: 14px;
  }

  /deep/ .el-collapse-item__header.is-active {
    color: #fe6a00;
  }
}
.seach {
  display: inline-block;
  width: 54px;
  height: 30px;
  background: #fe6a00;
  border-radius: 4px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  margin: 1% 0;
}
.right {
  min-width: 1020px;
  width: calc(100% - 256px - 10px);
  background: #fff;
  padding: 18px 20px 10px 20px;
  float: right;
  margin-top: 10px;

  .project_toptit {
    overflow: hidden;
    zoom: 1;
    margin-bottom: 10px;

    dt {
      float: left;
      font-size: 20px;
      color: #333;
      height: 30px;
      line-height: 30px;
    }
    dd {
      float: right;

      .xzeng_btn {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        background: #fe6a00;
        border-radius: 4px;
        font-size: 14px;
        text-align: center;
        color: #ffffff;
        margin: 1% 5px;
        padding: 0 20px;
      }
    }
  }

  .proje_ul {
    background: #f3f6fb;
    padding: 20px 30px;
    overflow: hidden;
    zoom: 1;
    margin-bottom: 15px;
    li {
      width: 25%;
      float: left;
      // padding-right: 1%;
      font-size: 14px;
      text-align: left;
      line-height: 34px;
      margin-bottom: 10px;

      strong {
        font-weight: normal;
        display: block;
        width: 85px;
        float: left;
      }
      span {
        display: block;
        float: left;
        width: calc(100% - 85px);
        position: relative;
      }
      .chengzi_btn {
        color: #fe6a00;
        text-decoration: underline;
      }
      /deep/.el-input {
        width: 80%;
        height: 32px;
        line-height: 32px;

        input {
          height: 32px;
          line-height: 32px;
          border-radius: 0;
          color: #333;
        }
        ::-webkit-input-placeholder {
          color: #333;
        }
      }

      /deep/.el-input__icon {
        line-height: 32px;
      }

      /deep/.el-select {
        height: 30px;
        input {
          width: 100%;
          height: 30px;
          background: #ffffff;
          border: 1px solid #dddddd;
        }
        /deep/.el-input__icon {
          line-height: 30px !important;
        }
      }
    }

    .qy_names {
      width: 100%;
      float: none;
      height: 34px;
      /deep/.el-input {
        width: 300px;
      }
    }
    /deep/.el-input--prefix .el-input__inner {
      padding: 0 25px;
    }
    .li_widan {
      strong {
        width: 115px;
      }
      span {
        width: calc(100% - 115px);

        /deep/.el-input {
          width: 74%;
        }
      }
    }
  }

  .xm_dengji {
    font-size: 14px;
    text-align: left;
    line-height: 34px;
    overflow: hidden;
    zoom: 1;

    li {
      margin-right: 25px;
      float: left;
    }

    span {
      color: #fe6a00;
      background: #fff0e5;
      border-radius: 3px;
      padding: 5px 35px;
    }

    /deep/.el-select {
      width: 170px;
      height: 30px;
      input {
        width: 100%;
        height: 30px;
        background: #ffffff;
        color: #333;
        border: 1px solid #dddddd;
      }
      ::-webkit-input-placeholder {
        color: #333;
      }
      /deep/.el-input__icon {
        line-height: 30px !important;
      }
    }
  }

  .project_main {
    overflow: hidden;
    zoom: 1;

    .project_tit {
      overflow: hidden;
      zoom: 1;
      margin: 15px 0 6px 0;

      dt {
        float: left;
        font-weight: bold;
        font-size: 16px;
        color: #fe6a00;
        height: 30px;
        line-height: 30px;
      }

      dt:before {
        content: " ";
        padding: 0 3px 0 0;
        margin-right: 10px;
        background: #fe6a00;
      }

      dd {
        float: right;

        .xzeng_btn {
          display: inline-block;
          height: 30px;
          line-height: 30px;
          background: #fe6a00;
          border-radius: 4px;
          font-size: 14px;
          text-align: center;
          color: #ffffff;
          margin: 1% 5px;
          padding: 0 20px;
        }
      }
    }

    .pre_xtit {
      font-size: 14px;
      text-align: left;
      line-height: 28px;
      margin-left: 12px;
      font-weight: normal;
    }
    .pre_xtit::before {
      content: " ";
      display: block;
      float: left;
      width: 6px;
      height: 6px;
      margin-right: 8px;
      margin-top: 10px;
      background: #333;
    }

    .project_area {
      text-align: left;
      font-size: 14px;
      height: 100px;
      line-height: 28px;
      margin-left: 15px;
      margin-bottom: 10px;

      /deep/.el-textarea__inner {
        height: 100px;
        padding: 12px;
      }
      /deep/.el-textarea__inner:focus {
        border-color: #fe6a00;
      }
    }

    .project_tables {
      font-size: 14px;
      line-height: 44px;
      margin-bottom: 20px;
      width: 100%;

      tr:nth-child(odd) {
        background: #f3f6fb;

        td {
          border-top: 1px solid #eee;
          border-bottom: 1px solid #eee;
        }
      }
      span {
        margin: 0 2%;
      }
      .chengzi_btn {
        color: #fe6a00;
      }
      .lvzi_btn {
        color: #2cb54c;
      }
      .zizi_btn {
        color: #135de6;
      }
      .redzi_btn {
        color: #e51313;
      }
      .bluezi_btn {
        color: #0ebbf3;
      }
      .huangzi_btn {
        color: #e69554;
      }

      /deep/.el-button {
        background: none;
        padding: 0;
        border: 0;
      }
    }

    .pro_btns {
      display: flex;
      justify-content: center;
    }
    .chengse_btn {
      display: inline-block;
      height: 30px;
      line-height: 30px;
      background: #fe6a00;
      border-radius: 4px;
      font-size: 14px;
      text-align: center;
      color: #ffffff;
      margin: 1% 5px;
      padding: 0 20px;
    }
    .chengse_kong {
      display: inline-block;
      height: 28px;
      line-height: 28px;
      border: 1px solid #fe6a00;
      border-radius: 4px;
      font-size: 14px;
      text-align: center;
      color: #fe6a00;
      margin: 1% 5px;
      padding: 0 20px;
    }
  }
}
/deep/.el-upload-list__item-name,
/deep/.el-upload-list {
  display: none !important;
}
</style>